import React from "react"
import { Link } from "gatsby"
import Slugger from "github-slugger"

import "./index.scss"
const More = ({ headings = [] }) => {
  const slugger = new Slugger()

  return headings.length > 0 ? (
    <ul id="ToC" className="tocc-container" data-testid="More">
      <h6 className="toc-title">On this page</h6>
      <div className="toc-innerScroll">
        {headings.map((heading, index) => {
          return (
            <li
              key={index}
              className={`toc-element padding-depth-${heading.depth}`}
            >
              <Link
                className={`toc-link`}
                to={"#" + slugger.slug(heading.value)}
                dangerouslySetInnerHTML={{ __html: heading.value }}
              ></Link>
            </li>
          )
        })}
      </div>
    </ul>
  ) : (
    <div data-testid="More"></div>
  )
}

export default More
