import { Link } from "gatsby"
import React from "react"

import "./index.scss"
import LoginForm from "../console/authentication/login/login-form"

function PrivateContent() {
  return (
    <div id="PrivateContent" className="row private-content">
      <div className="private-content-message col-md-12">
        <h1>
          This is a private content for logged in clients only
          <br />
          please <span>login</span> or <Link to="/console/signup/">signup</Link>
        </h1>
      </div>
      <div className="login-form-container col-md-12">
        <LoginForm />
      </div>
    </div>
  )
}

export default PrivateContent
