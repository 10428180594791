import React from "react"
import "./index.scss"

const LinkRef = ({ gotoref, title, number, to, internal }) => {
  return gotoref ? (
    <li id={`references_${gotoref}`}>
      <a href={`#reference_${gotoref}`}>[{gotoref}]</a> -
      {to ? (
        <a target="_blank" rel="noopener noreferrer" href={to}>
          {"  "}
          {title}
        </a>
      ) : (
        <p className="maxapps_linkref_p">{"   " + title}</p>
      )}
    </li>
  ) : (
    <sup id={`reference_${number}`}>
      {to ? (
        <a target="_blank" rel="noopener noreferrer" href={to}>
          [{number}]
        </a>
      ) : internal ? (
        <a href={`#references_${number}`}>[{number}]</a>
      ) : (
        "[" + number + "]"
      )}
    </sup>
  )
}
export default LinkRef
