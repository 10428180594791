import React from "react"
import "./index.scss"

const Success = props => (
  <span className="maxapps-note-svg">
    <svg viewBox="0 0 16 16" fill="none" className="success" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m11.677 6.16-4.443 4.444a.5.5 0 0 1-.708 0L4.323 8.4l.707-.707 1.85 1.85 4.09-4.09.707.707z"
        fill="currentColor"
      />
    </svg>
  </span>
)

const Warning = props => (
  <span className="maxapps-note-svg">
    <svg viewBox="0 0 16 16" fill="none" className="Warning" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 8.96V3.29h1v5.67h-1z"
        fill="currentColor"
      />
      <path d="M8 12.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"
        fill="currentColor"
      />
    </svg>
  </span>
)

const Danger = props => {
  return (
    <span className="maxapps-note-svg">
      <svg viewBox="0 0 16 16" fill="none" className="Danger" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a.5.5 0 0 1 .424.235l7.5 12A.5.5 0 0 1 15.5 14H.5a.5.5 0 0 1-.424-.765l7.5-12A.5.5 0 0 1 8 1zM1.402 13h13.196L8 2.443 1.402 13z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 9.41V6.12h1v3.29h-1z"
          fill="currentColor"
        />
        <path
          d="M8 11.67a.76.76 0 1 1 0-1.52.76.76 0 0 1 0 1.52z"
          fill="currentColor"
        />
      </svg>
    </span>
  )
}

const Info = props => {
  return (
    <span className="maxapps-note-svg">
      <svg viewBox="0 0 16 16" fill="none" className="Info" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 6.37H8a.5.5 0 0 1 .5.5v5.5h-1v-5h-1v-1z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 12.37h-3v-1h3v1z"
          fill="currentColor"
        />
        <path
          d="M7.74 5.16a.77.77 0 0 1-.76-.77.76.76 0 0 1 1.52 0 .77.77 0 0 1-.76.77z"
          fill="currentColor"
        />
      </svg>
    </span>
  )
}

const Blockquote = ({ type = "info", withSVG = null, children }) => {
  const typeToClassName = {
    old: "maxaaps-note-default",
    "-1": "maxaaps-note-default",
    info: "maxaaps-note-info",
    0: "maxaaps-note-info",
    important: "maxaaps-note-important",
    1: "maxaaps-note-important",
    warning: "maxaaps-note-warning",
    2: "maxaaps-note-warning",
    success: "maxaaps-note-success",
    3: "maxaaps-note-success",
  }

  const typeToSvg = {
    old: null,
    "-1": null,
    info: <Info />,
    0: <Info />,
    important: <Danger />,
    1: <Danger />,
    warning: <Warning />,
    2: <Warning />,
    success: <Success />,
    3: <Success />,
  }

  return (
    <blockquote className={typeToClassName[type]} data-testid="Blockquote">
      <div className="maxapps-note">
        {withSVG && typeToSvg[type]}
        <span className="maxapps-note-content">{children}</span>
      </div>
    </blockquote>
  )
}
export default Blockquote
