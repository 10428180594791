import { Link } from 'gatsby'
import React from 'react'
import { BsArrowRight } from "react-icons/bs"


const ImxLink = ({to: linkto}) => {
    return (
        <fragment id="imx-link">
            <Link to={linkto}><BsArrowRight size={25} /></Link>
        </fragment>
    )
}

export default ImxLink
