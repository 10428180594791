import React, { useEffect, useState } from "react"
import axios from "axios"

import dbServices from "../../services/firebase/db"
import { getFromStorage } from "../../services/utils"

import "./index.scss"
import Addcomment from "./addcomment"

const Comments = ({ props }) => {
  const [listcomment, setListComment] = useState("")
  const [removeconfirm, setRemoveconfirm] = useState(null)
  const [editComment, setEditComment] = useState(null)
  const profileLS = JSON.parse(getFromStorage("profile") || "{}")
  const commentDoc = isBrowser() && window.location.pathname
  const [newComment, setNewComment] = useState("")

  const handleDelete = async idcomment => {
    const slug = commentDoc?.replace(new RegExp("/", "g"), "")

    const res = await dbServices.deleteComment(slug, idcomment)
  }

  const handleEdit = async idcomment => {
    const slug = commentDoc?.replace(new RegExp("/", "g"), "")
    const userData = await getUserData()

    const newcomment = {
      id: idcomment,
      comment: newComment,
      userData,
    }

    const res = await dbServices.UpdateComment(slug, idcomment, newcomment)
    setEditComment(null)
  }

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const res = await dbServices.getComment(
        commentDoc?.replace(new RegExp("/", "g"), "")
      )

      setListComment(res)
    }, 1500)
    return () => {
      clearInterval(intervalId)
    }
  }, [listcomment])

  const convertdate = date => {
    return new Intl.DateTimeFormat("fr-FR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(date)
  }

  const convertTime = date => {
    return new Intl.DateTimeFormat("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    }).format(date)
  }

  return (
    <div className="comments" data-testid="comments">
      <section className="content-item" id="comments">
        <div className="container">
          <div className="row">
            <div className="col-sm-10">
              <Addcomment getUserData={getUserData} />
              <h3>
                {listcomment?.comments?.length
                  ? listcomment?.comments?.length
                  : 0}{" "}
                comments
              </h3>

              {listcomment?.comments?.map((data, index) => (
                <div className="media" key={index}>
                  <div className="media-body">
                    <p className="media-heading">{data.userData.userName}</p>
                    {editComment && editComment === data.id ? (
                      <textarea
                        className="form-control p-4"
                        id="message"
                        placeholder="New comment"
                        defaultValue={data.comment}
                        required="required"
                        onChange={e => setNewComment(e.target.value)}
                        rows="3"
                      ></textarea>
                    ) : (
                      <p className="media-description">{data.comment}</p>
                    )}
                    <ul className="list-unstyled list-inline media-detail pull-left">
                      <li className="d-flex">
                        {convertdate(data.userData.date)} -{" "}
                        {convertTime(data.userData.date)}
                        {data.userData.userID === profileLS?.id && (
                          <>
                            {editComment && editComment === data.id ? (
                              <a
                                type="button"
                                className="pl media-delete"
                                id={data.id}
                                onClick={() => handleEdit(data.id)}
                              >
                                Save
                              </a>
                            ) : (
                              <a
                                type="button"
                                className="pl media-delete"
                                id={data.id}
                                onClick={() => setEditComment(data.id)}
                              >
                                Edit
                              </a>
                            )}
                            |
                            {removeconfirm && removeconfirm === data.id ? (
                              <div className="pl remove-content">
                                <div className="remove-body text-center">
                                  Are you sure ?
                                </div>
                                <div className="remove-footer">
                                  <a
                                    type="button"
                                    className="pl media-delete"
                                    onClick={() => setRemoveconfirm(null)}
                                  >
                                    No
                                  </a>
                                  |
                                  <a
                                    type="button"
                                    className="media-delete"
                                    onClick={() => handleDelete(data.id)}
                                  >
                                    Yes
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <a
                                type="button"
                                className="media-delete"
                                id={data.id}
                                onClick={() => setRemoveconfirm(data.id)}
                              >
                                Remove
                              </a>
                            )}
                          </>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
const isBrowser = () => typeof window !== "undefined"
const getUserData = async () => {
  const res = await axios.get("https://geolocation-db.com/json/")
  const profileLS = JSON.parse(getFromStorage("profile") || "{}")
  return {
    userID: profileLS?.id || "guest",
    userName: profileLS?.profile?.displayName || "guest",
    IPv4: res.data.IPv4,
    country_name: res.data.country_name,
    date: Date.now(),
  }
}
export default Comments
