import { graphql } from "gatsby"
import React from "react"

import Article from "../components/article"
import Layout from "../components/layout/t1/layout"
import PrivateContent from "../components/private-content"
import { isLoggedIn } from "../services/utils"

const ArticlePage = ({ data }) => {
  return (
    <Layout>
      {isLoggedIn() ? <Article data={data} /> : <PrivateContent />}
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        title
        next
        previous
        date
        author
        picture
        video
        description
        withComment
        general
      }
      headings {
        depth
        value
      }
      body
    }
  }
`

export default ArticlePage
