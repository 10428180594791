import React from "react"
import { Helmet } from "react-helmet"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { useStaticQuery, graphql } from "gatsby"

import "./index.scss"
import ImxLink from "../imx-links/index"
import ImageDocs from "../layout/docs-layout/image"
import Blockquote from "../blockquote-docs"
import Figure from "../figure"
import BlockQuote from "../use-cases/use-cases-detail/blockquote"
import LinkRef from "../link-ref"
import More from "./side-parts/more"
import Share from "./side-parts/share"
import ArticleItem from "../blog/article-item"
import Comments from "../comments"

const components = {
  ImxLink,
  ImageDocs,
  Blockquote,
  Figure,
  BlockQuote,
  LinkRef,
}

function Article({ data }) {
  const content = data.mdx.body
  const headings = data.mdx.headings
  let pageurl = ""
  typeof window !== "undefined" && (pageurl = window.location.href)
  let articlesList = []
  try {
    const result = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(blog)/" } }) {
          nodes {
            frontmatter {
              title
              author
              date
              description
              picture
              withComment
            }
            slug
          }
        }
      }
    `)
    articlesList = result.allMdx.nodes.slice(0, 3)
  } catch (error) {
    articlesList = []
  }
  return (
    <main>
      <Helmet prepend>
        <title>{`${data.mdx.frontmatter.title}  MAXapps`}</title>
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <meta name="rights" content="© Copyright 2022 MAXapps Maximo Mobile" />
        <meta property="og:author" content={pageurl} />
        <meta property="og:site_name" content="maxapps-maximo" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={pageurl} />
        <meta property="og:locale" content="en-US" />
        <meta
          id="og-title"
          property="og:title"
          content={data.mdx.frontmatter.title}
        />
        <meta
          id="meta-description"
          property="og:description"
          content={data.mdx.frontmatter.description}
        />
        <meta
          id="meta-description"
          name="description"
          content={data.mdx.frontmatter.description}
        />
        <meta
          id="og-image"
          property="og:image"
          content={`https://maxapps-maximo.com${data.mdx.frontmatter?.picture}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@maxpapps" />
        <meta name="twitter:creator" content="@maxapps" />
        <meta
          name="twitter:title"
          content={`${data.mdx.frontmatter.title} | MAXapps`}
        />
        <meta
          name="twitter:description"
          content={data.mdx.frontmatter.description}
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content={pageurl} />
        <meta property="twitter:url" content={pageurl} />
        <meta
          property="twitter:title"
          content={`${data.mdx.frontmatter.title} | MAXapps`}
        />
        <meta
          property="twitter:description"
          content={data.mdx.frontmatter.description}
        />
        <meta
          property="twitter:image:src"
          content={`https://maxapps-maximo.com${data.mdx.frontmatter?.picture}`}
        />
        <meta
          property="twitter:image"
          content={`https://maxapps-maximo.com${data.mdx.frontmatter?.picture}`}
        />
      </Helmet>
      <article
        id="Article"
        className=" row articleContainer"
        data-testid="Article"
      >
        <div className="col-lg-9">
          <section className="row titlearticle">
            <header className="col-12 col-lg-11 pt-3">
              <h1 className="mb-2 ">{data.mdx.frontmatter.title}</h1>
              <div className="mb-4">
                <span className="faded ">Last updated </span>
                <time className="updated ">{data.mdx.frontmatter.date}</time>
              </div>
            </header>
          </section>

          {data.mdx.frontmatter?.video ? (
            <section className="article-video-header">
              <iframe
                className="card-video-top"
                alt={`IBM Maximo: ${
                  data.mdx.frontmatter?.general
                    ? "IBM Maximo Mobile Solution for Your Enterprise"
                    : data.mdx.frontmatter?.title
                }`}
                title={`IBM Maximo: ${
                  data.mdx.frontmatter?.general
                    ? "IBM Maximo Mobile Solution for Your Enterprise"
                    : data.mdx.frontmatter?.title
                }`}
                src={data.mdx.frontmatter?.video}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </section>
          ) : (
            <section className="article-pic-header">
              {data.mdx.frontmatter?.picture ? (
                <img
                  src={data.mdx.frontmatter?.picture}
                  className="card-img-top"
                  alt={`IBM Maximo: ${
                    data.mdx.frontmatter?.general
                      ? "IBM Maximo Mobile Solution for Your Enterprise"
                      : data.mdx.frontmatter?.title
                  }`}
                  title={`IBM Maximo: ${
                    data.mdx.frontmatter?.general
                      ? "IBM Maximo Mobile Solution for Your Enterprise"
                      : data.mdx.frontmatter?.title
                  }`}
                />
              ) : (
                <img
                  src="/maxapps.jpg"
                  className="card-img-top"
                  alt={`IBM Maximo: ${
                    data.mdx.frontmatter?.general
                      ? "IBM Maximo Mobile Solution for Your Enterprise"
                      : data.mdx.frontmatter?.title
                  }`}
                  title={`IBM Maximo: ${
                    data.mdx.frontmatter?.general
                      ? "IBM Maximo Mobile Solution for Your Enterprise"
                      : data.mdx.frontmatter?.title
                  }`}
                />
              )}
            </section>
          )}
          <section className="articleContent col-lg-11">
            {content && (
              <MDXProvider components={components}>
                <MDXRenderer>{content}</MDXRenderer>
              </MDXProvider>
            )}
          </section>

          <div className="blog-separator"></div>
          <div className="blogshare col-3 col-md-3 text-right text-md-first ">
            <Share
              description={data.mdx.frontmatter.description}
              image={`https://maxapps-maximo.com${data.mdx.frontmatter?.picture}`}
            />
          </div>
          <div className="blog-separator"></div>
        </div>
        <div className="rightSection col-lg-3">
          <More headings={headings} />
        </div>
      </article>
      {data.mdx.frontmatter.withComment && <Comments />}

      <footer
        id="Article"
        className="container row articleContainer recentPost"
      >
        <div className="header">
          <p>Recent Posts</p>
          <a className="seeall" href="/blog/">
            See All
          </a>
        </div>
        <div className="row">
          {articlesList?.map((data, index) => (
            <div key={index} className=" col-md-4  p-0 p-md-3">
              <ArticleItem data={data} />
            </div>
          ))}
        </div>
      </footer>
    </main>
  )
}

export default Article
