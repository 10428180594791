import React from "react"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share"
import * as icon from "react-icons/ai"
import { toast, ToastContainer } from "react-toastify"
import "../index.scss"

function Share({ description = "", image = "" }) {
  let pageurl = ""
  typeof window !== "undefined" && (pageurl = window.location.href)

  const handleClick = () => {
    navigator.clipboard.writeText(pageurl)
    toast.info("Link copied!", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  return (
    <div id="Share" data-testid="Share">
      <ToastContainer />

      <FacebookShareButton
        url={pageurl}
        quote={description}
        imageURL={image}
        hashtag={"#maxapps"}
        description={description}
        className="text-black"
      >
        <icon.AiOutlineFacebook
          size={30}
          alt="IBM Imaxeam: Facebook "
          title="IBM Imaxeam: Facebook "
        />
      </FacebookShareButton>
      <TwitterShareButton
        url={pageurl}
        quote={description}
        hashtag={"#maxapps"}
        imageURL={image}
        description={description}
        className="text-black"
      >
        <icon.AiOutlineTwitter
          size={30}
          alt="IBM Imaxeam: Twitter "
          title="IBM Imaxeam: Twitter "
        />
      </TwitterShareButton>
      <LinkedinShareButton
        url={pageurl}
        quote={description}
        imageURL={image}
        hashtag={"#maxapps"}
        description={description}
        className="text-black"
      >
        <icon.AiOutlineLinkedin
          size={30}
          alt="IBM Imaxeam: LinkedIn "
          title="IBM Imaxeam: LinkedIn "
        />
      </LinkedinShareButton>
      <button className="text-black button-copy" onClick={() => handleClick()}>
        <icon.AiOutlineLink
          size={30}
          alt="IBM Imaxeam: OutlineLink "
          title="IBM Imaxeam: OutlineLink "
        />
      </button>
    </div>
  )
}

export default Share
