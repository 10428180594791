import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import LoginForm from "../console/authentication/login/login-form"
import dbServices from "../../services/firebase/db"

import "./index.scss"

const Addcomment = ({ getUserData = () => {} }) => {
  const [comment, setComment] = useState("")
  const [requiredd, setRequiredd] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const Rating = {
    comment: comment,
    commentDoc: isBrowser() && window.location.pathname,
  }
  const sendComment = async () => {
    setRequiredd(false)
    const userData = getUserData instanceof Function && (await getUserData())
    if (userData && userData?.userID !== "guest") {
      await dbServices.addComment({
        id: Rating?.commentDoc?.replace(new RegExp("/", "g"), ""),
        pageSlug: Rating?.commentDoc,
        comments: [
          {
            id: userData?.date + userData?.userID,
            comment: Rating?.comment,
            userData,
          },
        ],
      })
      setComment("")
    } else setShowModal(true)

    return
  }
  return (
    <>
      <div className="form-group col-xs-12 pb-4">
        <textarea
          className="form-control p-4"
          id="message"
          placeholder="New comment"
          value={comment}
          required="required"
          onChange={e => setComment(e.target.value)}
          rows="3"
        ></textarea>
        {requiredd && (
          <label className="error-message">*This field is required</label>
        )}
      </div>
      <div className="row px-3">
        <button
          type="submit"
          className="btn btn-normal pull-right col-3 "
          onClick={async () => {
            comment ? await sendComment() : setRequiredd(true)
          }}
        >
          Publish
        </button>
      </div>
      <Modal
        dialogClassName="comments-login-modal"
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="row">
            <p className="col-10"> You should connect first </p>
            <div className="col-2 text-end">
              <i
                onClick={() => {
                  setShowModal(false)
                }}
                className="bi bi-x-lg  "
              ></i>
            </div>
          </div>

          <LoginForm setShowModal={setShowModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
const isBrowser = () => typeof window !== "undefined"

export default Addcomment
